window.validateFileFormat = function(_target, callback=(mesg) => { if(mesg){ alert(mesg)} }, index=0) {
  let target = $(_target)
  let file = _target.files[index]
  let file_format = target.data('file-format')
  let file_extensions = target.data('file-extensions')
  let valid_file_extensions = { 'video': 'mp4,webm', 'image': 'jpg,jpeg,png,gif,svg', 'csv': 'csv' }

  let is_file_format = function(file, file_format) {
    file_extensions = valid_file_extensions[file_format]
    let file_extensions_regex = new RegExp(`\.(${file_extensions.replaceAll(',', '|')})$`, 'i')
    return file.name.match(file_extensions_regex)
  }

  if (!file_extensions) {
    file_extensions = valid_file_extensions[file_format]
  }
  if (file_extensions) {
    let file_extensions_regex = new RegExp(`\.(${file_extensions.replaceAll(',', '|')})$`, 'i')
    if (file.name.match(file_extensions_regex) == null) {
      target.val(null)
      callback(`This file format is not accepted. Please use ${file_extensions}.`)
      return
    }
  }

  let file_size_kb = file.size / 1024

  if (file_format == 'video' || (file_format == 'auto' && is_file_format(file, 'video') ) ) {
    let reader  = new FileReader();
    let fileType =  file.type;

    reader.addEventListener("load", function () {
      var dataUrl =  reader.result;
      var videoId = "tempUploadVideo";
      var videoEl = $(`<video id="${videoId}" src="${dataUrl}" style="display:none"></video>`);
      $("body").append(videoEl);

      var video = videoEl[0];
      video.addEventListener('loadedmetadata', function(e){
        let duration = video.duration
        let aspect_ratio = video.videoWidth / video.videoHeight
        let error = ""

        let required_aspect_ratio_str = target.data('aspect-ratio')
        let required_aspect_ratio = null
        if (required_aspect_ratio_str) {
          let required_aspect_ratio_parts = required_aspect_ratio_str.split(":")
          required_aspect_ratio = parseInt(required_aspect_ratio_parts[0]) / parseInt(required_aspect_ratio_parts[1])
        }
        if (required_aspect_ratio && required_aspect_ratio != aspect_ratio) {
          error += ` The provided video does not have correct aspect ratio. Please prodive video with aspect ratio of ${required_aspect_ratio_str}.`
        }

        let max_duration_str = target.data('max-duration')
        if (max_duration_str && duration > parseFloat(max_duration_str)) {
          error += ` The provided video exceeds max duration of ${max_duration_str} seconds.`
        }

        let required_properties = ['max-size'].reduce( (c,f) => {c[f] = target.data(f); return c }, {} )
        required_properties['max-size'] ||= 1024
        if (file_size_kb > required_properties['max-size']) {
          target.val(null)
          error += `File exceeds the size limit of ${required_properties['max-size']}KB.`
        }

        if ( error != "") {
          target.val(null)
          $("#tempUploadVideo").remove()
          callback(error)
        }

        video.play()
        video.addEventListener('loadeddata', function () {
          setTimeout(() => {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            document.body.appendChild(canvas)
            canvas.style.display = 'none'
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
            const image_data = canvas.toDataURL("image/png")
            $("#tempUploadVideo").remove()
            callback(null, { image_data, type: 'video' } )
          }, 100)
        })
      });
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  if (file_format == 'image' || (file_format == 'auto' && is_file_format(file, 'image'))) {
    let required_aspect_ratio_str = target.data('aspect-ratio')
    let required_aspect_ratio = null
    if (required_aspect_ratio_str) {
      let required_aspect_ratio_parts = required_aspect_ratio_str.split(":")
      required_aspect_ratio = parseInt(required_aspect_ratio_parts[0]) / parseInt(required_aspect_ratio_parts[1])
    }
    let required_properties = ['min-height', 'min-width', 'max-height', 'max-width', 'max-size'].reduce( (c,f) => {c[f] = target.data(f); return c }, {} )
    required_properties['max-size'] ||= 2048

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      let image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        let height = this.height;
        let width = this.width;
        let aspect_ratio = width/height;

        let error = ""

        if (required_aspect_ratio && required_aspect_ratio != aspect_ratio) {
          error += ` The provided image does not have correct aspect ratio. Please prodive image with aspect ratio of ${required_aspect_ratio_str}.`
        }
        if ( (required_properties['min-height'] && height < required_properties['min-height']) || (required_properties['min-width'] && width < required_properties['min-width']) ) {
          error += ` The provided image should have a minimum size of ${required_properties['min-width']} X ${required_properties['min-height']}.`
        }
        if ( (required_properties['max-height'] && height > required_properties['max-height']) || (required_properties['max-width'] && width > required_properties['max-width']) ) {
          error += ` The provided image should have a maximum size of ${required_properties['max-width']} X ${required_properties['max-height']}.`
        }
        if ( required_properties['max-size'] && file_size_kb > required_properties['max-size'] ) {
          error += ` File exceeds the size limit of ${required_properties['max-size']}KBs.`
        }

        if ( error != "") {
          target.val(null)
          callback(error)
        } else {
          callback(null)
        }
        return true;
      }
    }
  }
}
$('.file-format-validation').on('change', (e) => {
  validateFileFormat(e.target)
})
