// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.$ = $
window.jQuery = jQuery
window.Waves = Waves
window.SimpleBar = SimpleBar

require("utils")
require("file_format_validation")
require("bootstrap")
require("layout")
require("minton")
require("datatables.net")
require("datatables.net-bs4")
require("datatables.net-responsive")
require("datatables.net-responsive-bs4")
require("datatables.net-buttons")
require("datatables.net-buttons-bs4")
require("datatables.net-rowreorder")
require("datatables.net-rowgroup")
window.ApexCharts = require("apexcharts/dist/apexcharts")
require("jquery-toast-plugin")
window.moment = require("moment")
require("daterangepicker")
require("jquery-ui-sortable")
require("selectize")
require("spectrum-colorpicker2")
import Sortable from 'sortablejs'
window.Sortable = Sortable
import Quill from 'quill'
window.Quill = Quill
